<template>
  <v-container fluid
    :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3"
        width="97%"
        height="70">
        <v-row>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportFromMenu"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportToMenu"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-select outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object></v-select>
          </div>
          <div style="
              max-width: 25% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
            ">
            <v-btn :disabled="locationNotSelected"
              class="mr-3"
              @click="getData()"><v-icon>mdi-magnify</v-icon></v-btn>
            <v-btn class="mr-2"
              @click="changeWidth()"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
            <v-btn class="ml-2"
              @click="generateExcel()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
            <v-btn class="ml-3"
              :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        ">
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma"
          style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Codes by Quackit.com -->

        <!--  -->
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title">
            <thead>
              <tr class="pb-0 mb-0">
                <th colspan="6"
                  class="pb-0 mb-0">
                  <p style="font-weight: bold; font-size: 25px">
                    Rekapitulacija napojnica
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div justify="center">
          <table justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="#cacccb"
            id="tableTraffic"
            class="tableTraffic"
            v-if="showTableData">
            <!-- <thead>
              <tr :class="['header', 'naslov']">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead> -->
            <tbody v-for="seller in sellers"
              :key="seller.id"
              style="min-width: 100% !important">
              <tr style="
                  text-align: left;
                  font-weight: bold;
                  min-width: 100% !important;
                  background-color: #757575;
                  color: white;
                ">
                <td style="text-transform: uppercase">{{ seller.name }}</td>
                <td>
                  Količina napojnica - {{ seller.cash_amt + seller.card_amt }}
                </td>
              </tr>
              <tr style="
                  text-align: left;
                  font-weight: bold;
                  background-color: #d3d3d3;
                ">
                <td colspan="2">Način plaćanja</td>
              </tr>
              <tr>
                <td>Gotovina</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(seller.cash, 100, currency)
                        .replace("€", "")
                      : seller.cash / 100
                  }}
                </td>
              </tr>
              <tr>
                <td>Kartice</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(seller.card, 100, currency)
                        .replace("€", "")
                      : seller.card / 100
                  }}
                </td>
              </tr>
              <tr>
                <td>Ukupno</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(seller.card + seller.cash, 100, currency)
                        .replace("€", "")
                      : seller.card + seller.cash / 100
                  }}
                </td>
              </tr>
              <tr style="background-color border-right: 0px white !important">
                <td colspan="3">&nbsp;</td>
              </tr>
            </tbody>
            <tbody style="min-width: 100% !important">
              <tr style="
                  text-align: left;
                  font-weight: bold;
                  background-color: #d3d3d3;
                ">
                <td colspan="2">Ukupno po načinima plaćanja</td>
              </tr>
              <tr>
                <td>Gotovina</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(total.cash, 100, currency)
                        .replace("€", "")
                      : total.cash / 100
                  }}
                </td>
              </tr>
              <tr>
                <td>Kartice</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(total.card, 100, currency)
                        .replace("€", "")
                      : total.card / 100
                  }}
                </td>
              </tr>
              <tr>
                <td>Ukupno</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(total.total, 100, currency)
                        .replace("€", "")
                      : total.total / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: {},
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      location: null,
      locations: [],
      allTips: [],
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      currency: 'EUR',
      total: {
        cash: 0,
        card: 0,
        total: 0
      },
      sellers: [],
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      showTableData: false
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())
  },
  methods: {
    async getData () {
      this.sellers = []
      this.tips = {
        cash: 0,
        card: 0,
        total: 0
      }
      this.total = {
        cash: 0,
        card: 0,
        total: 0
      }
      this.showLoader()
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000

      this.allTips = []

      let query = df.collection('tips')
        .where('created', '>=', dateFrom)
        .where('created', '<=', dateTo + 86399)
      if (this.location && this.location.id !== 'all') {
        query = query.where('location_id', '==', this.location.id)
      }

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              this.allTips.push(item.data())
            })
          }
        })

      this.allTips.sort((a, b) => a.created - b.created) // b - a for reverse sort

      this.allTips.forEach(tip => {
        var seller = {
          name: tip.seller_name,
          id: tip.seller_id,
          cash: 0,
          cash_amt: 0,
          card: 0,
          card_amt: 0,
          tips: [tip]
        }
        this.total.total += tip.tip_amount

        if (tip.tip_payment_method === 'NOVČANICE') {
          this.total.cash += tip.tip_amount
          seller.cash += tip.tip_amount
          seller.cash_amt += 1
        }

        if (tip.tip_payment_method === 'KARTICA') {
          this.total.card += tip.tip_amount
          seller.card += tip.tip_amount
          seller.card_amt += 1
        }

        if (this.sellers.length === 0) {
          this.sellers.push(seller)
        } else {
          var found = false
          this.sellers.forEach((s, k) => {
            if (s.id === seller.id) {
              found = true
              if (seller.card > 0) {
                this.sellers[k].card += seller.card
                this.sellers[k].card_amt += seller.card_amt
              }

              if (seller.cash > 0) {
                this.sellers[k].cash += seller.cash
                this.sellers[k].cash_amt += seller.cash_amt
              }

              this.sellers[k].tips.push(...seller.tips)
            }
          })

          if (found === false) {
            this.sellers.push(seller)
          }
        }
      })

      this.showTableData = true
      this.hideLoader()

      // this.calculateData()
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)

      doc = await setPageNumber(doc)

      doc.save('Rekapitulacija napojnica.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Rekapitulacija napojnica.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.location = all
    }
  }
}
</script>
<style scoped>
.blank_row {
  height: 25px !important;
  /* overwrites any other rules */
  background-color: #ffffff;
  border: 0px red;
  border: none !important;
}

.tableTraffic {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
